var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":""}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.$t('title.transfer')))])])],1),_c('v-divider',{staticClass:"mb-3"}),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_vm._l((_vm.requests),function(request){return _c('v-container',{key:request.id,staticClass:"request mt-3 mb-1"},[_c('v-row',[_c('v-col',{staticClass:"subtitle-2",attrs:{"align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(request.uniqueNo)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.request-no')))])])],1),_c('v-col',{attrs:{"align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formatDate(request.requestDate))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.request-date')))])])],1),_c('v-col',{attrs:{"align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formatDate(request.transferDate))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.transfer-date')))])])],1),_c('v-col',{attrs:{"align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"request-status body-2 px-2 py-1"},on),[_vm._v(" "+_vm._s(_vm.formatStatus(request.status))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.status')))])])],1),_c('v-col',{staticClass:"text-right",attrs:{"align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.formatAmount(request.totalAmount))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.total-amount')))])])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"1","align-self":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":'/transfer/' + request.uniqueNo,"target":"_blank","icon":""}},on),[_c('v-icon',[_vm._v("mdi-book-open")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('text.view')))])])],1)],1)],1)}),(_vm.requests.length)?_c('v-pagination',{staticClass:"pt-4 pb-3",attrs:{"length":_vm.pageCount,"total-visible":"12"},on:{"input":_vm.load},model:{value:(_vm.pageNo),callback:function ($$v) {_vm.pageNo=$$v},expression:"pageNo"}}):_vm._e(),(!_vm.requests.length)?_c('v-container',{staticClass:"request-no-data mt-3",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center body-2 my-2"},[_vm._v(_vm._s(_vm.$t("$vuetify.noDataText")))])],1)],1):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }